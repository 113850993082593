@import 'src/assets/scss/color-palette';

.btn {
  min-height: 60px;
  padding: 18px;
  height: 100%;
  width: 54px;

  position: relative;

  line-height: 18px;
  font-weight: 800;
  font-size: 18px;

  background: none;
  color: $white;
  border: none;

  &__connected {
    background-color: $light-blue;

    &:hover,
    &.isOpen {
      background-color: $cerulean;
    }
  }

  &__disconnected {
    background-color: $tower-gray;

    &:hover,
    &.isOpen {
      background-color: $regent-gray;
    }
  }

  .counter {
    padding: 2px 4px;

    position: absolute;
    right: 5px;
    top: 10px;

    justify-content: center;
    display: inline-flex;
    align-items: center;

    background-color: $jungle-green;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
  }

  .disconnected_icon {
    position: absolute;
    right: 10px;
    top: 12px;

    color: $monza;
    font-weight: bold;
  }
}

.tooltip {
  & > div:last-of-type {
    top: 55px;
    left: -110px;
  }
}
