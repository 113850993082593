// Custom.scss
// Option A: Include all of Bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import 'assets/scss/color-palette';

.invalid-feedback.error {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1rem;
  z-index: 2;
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  pointer-events: none;

  .fa {
    color: #dc3545;
  }
}

label {
  margin: 0;
}

.form-control {
  height: 34px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 15px;
}

.has-error .form-control {
  padding-right: 42.5px;
}

.has-feedback {
  position: relative;
}

button:focus {
  outline: none;
}

.modal-footer {
  justify-content: space-between;

  .button {
    min-width: 160px;
  }
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-link,
.nav-link:hover,
.nav-link:focus {
  &.tab_highlight {
    &_error {
      background-color: $fair-pink;

      &.active {
        background-color: $fair-pink;
        border-bottom: 3px solid $sunset-orange;
      }
    }

    &_warning {
      background-color: $seashell-peach;

      &.active {
        background-color: $seashell-peach;
        border-bottom: 3px solid $crusta;
      }
    }
  }
}

.nav-tabs .nav-link {
  border: none;

  &.active {
    color: inherit;
    background-color: inherit;
    border-color: #3fc4ff;

    &.tab_highlight {
      &_error {
        background-color: $fair-pink;
        border-bottom: 3px solid $sunset-orange;
      }
  
      &_warning {
        background-color: $seashell-peach;
        border-bottom: 3px solid $crusta;
      }
    }
  }
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #3fc4ff;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: inherit;
    width: 600px;
  }
}

// TEMP global class for implement new common ui-components
// after refactor badges we will remove this class
.pic {
  min-width: 20px;
  min-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #84939a;
}

.pic em {
  font-style: normal;
}

.archived {
  color: grey !important;
}

.line_break_any {
  line-break: anywhere;
}

.capitalize {
  text-transform: capitalize;
}