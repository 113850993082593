@import 'assets/scss/color-palette';

.wrap {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  background-color: $white;
  border-bottom: 1px solid $light-gray;
  cursor: pointer;

  &:hover {
    background: $light-gray;
  }
}

.info {
  width: 255px;
  color: $tuna;
}

.type {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

