@import "src/assets/scss/color-palette";

.wrap {
  padding: 30px 35px;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;

  background-color: $nevada;

  .info {
    padding-bottom: 15px;
    text-align: center;

    font-size: 16px;
    color: $white;
  }

  .btn {
    text-transform: capitalize;

    i {
      color: $cerulean;
    }
  }
}