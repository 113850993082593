@import "src/assets/scss/color-palette";

.wrap {
  padding: 10px 20px;

  align-items: center;
  display: flex;

  &.success {
    color: $white;
    background-color: $jungle-green;
  }

  &.failure {
    color: $bright-gray;
    background-color: $fair-pink;

    i {
      color: $monza;
    }
  }

  .icon {
    margin-right: 20px;
  }
}

